import React, { useState, useEffect, createContext, useContext, useCallback } from "react";
import { getUser, login as sendLoginRequest } from "../api/auth";
import type { User, AuthContextType } from "../types";
import defaultUser from "../utils/default-user";
import { useCartridge } from "../contexts/cartridge";

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const cartridge = useCartridge();

  useEffect(() => {
    (async function () {
      setUser(defaultUser);
    })();
    if (cartridge) {
      if (!cartridge.catNoList) cartridge.catNoList = new Array<string>();
      if (!cartridge.modelCategoryList) cartridge.modelCategoryList = new Array<string>();
      if (!cartridge.outflowList) cartridge.outflowList = new Array<number>();
    }
  }, []);

  const login = useCallback(async (affiliation: string, id: string, password: string) => {
    const result = await sendLoginRequest(affiliation, id, password);
    if (result.isOk) {
      setUser(result.data);
      cartridge.catNoList = result.cartridgeCatNoList;
      cartridge.modelCategoryList = result.cartridgeModelCategoryList;
      cartridge.outflowList = result.cartridgeOutflowList;
    }
    return result;
  }, []);

  const logout = useCallback(() => {
    setUser(defaultUser);
  }, []);

  return <AuthContext.Provider value={{ user, login, logout }} {...props} />;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
