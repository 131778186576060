import "./protocol.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useProtocol } from "../../contexts/protocol";
// api
import { queryJsonArray, deleteRowById } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function Protocol() {
  const { user } = useAuth();
  const protocolContext = useProtocol();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedId, setSelectedId] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(false);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_proj = 0;
    if (user) {
      setUserId(user.id);
      acc_proj = user.acc_proj;
    }
    // (proj) [3] Protocol R, [4] W, [5] D
    if (U.bitAt(acc_proj, 4)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_proj, 5)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  function onSearchButtonClicked() {
    search();
  }

  function search() {
    setSelectedId("");

    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let query = "SELECT * FROM protocol";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY startDate Desc";
    queryJsonArray("/project/queryprotocol", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < jsonData.length; i++) {
        // 기존 json row에 몇몇 column을 추가
        let oneRowJson: Object | null = jsonData[i];
        let maxVolume1: number = U.intValueFromJson(oneRowJson, "maxVolume");
        let maxVolume2: string = "Off";
        if (maxVolume1 === 1) maxVolume2 = "On";
        oneRowJson = U.addStringToJson(oneRowJson, "maxVolume2", maxVolume2);
        if (oneRowJson === null) continue;
        // ...
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }
      setJsonDataSource(newJsonData);
      //setJsonDataSource(jsonData);
    });
  }

  function onNewButtonClicked() {
    protocolContext.id = "";
    navigate("/protocol/new");
  }

  function copyToProtocolContext(obj: Object) {
    protocolContext.id = U.stringValueFromJson(obj, "id");
    protocolContext.deviceModel = U.stringValueFromJson(obj, "deviceModel");
    protocolContext.auxModule = U.stringValueFromJson(obj, "auxModule");
    protocolContext.cartridge = U.stringValueFromJson(obj, "cartridge");
    protocolContext.outflow = U.stringValueFromJson(obj, "outflow");
    protocolContext.mixingRatio = U.stringValueFromJson(obj, "mixingRatio");
    protocolContext.freq = U.stringValueFromJson(obj, "freq");
    protocolContext.voltage = U.stringValueFromJson(obj, "voltage");
    protocolContext.duty = U.stringValueFromJson(obj, "duty");
    protocolContext.coolerMode = U.stringValueFromJson(obj, "coolerMode");
    protocolContext.coolerT = U.stringValueFromJson(obj, "coolerT");
    protocolContext.maxVolume = U.stringValueFromJson(obj, "maxVolume");
    protocolContext.harvestVolume = U.stringValueFromJson(obj, "harvestVolume");
    protocolContext.numberOfUse = U.stringValueFromJson(obj, "numberOfUse");
  }

  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToProtocolContext(selectedRow);
    navigate("/protocol/new");
  }

  function onDeleteButtonClicked() {
    if (selectedId.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedId, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowById("/project/deleteprotocol", selectedId).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedId(e.selectedRowsData[0].id);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Protocol</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="success" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Edit" onClick={onEditButtonClicked} visible={isNewButtonVisible} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="id" caption="Protocol Name" />
              <Column dataField="startDate" caption="Start Date" dataType="date" />
              <Column dataField="numberOfUse" caption="Number of Use" dataType="number" format="#,##0.#" />
              <Column dataField="deviceModel" caption="Device Model" />
              <Column dataField="auxModule" caption="Aux Module" />
              <Column dataField="cartridge" caption="Cartridge" />
              <Column dataField="outflow" caption="Outflow (mL/h)" dataType="number" format="#,##0.#" />
              <Column dataField="mixingRatio" caption="Mixing Ratio (Mat/Cell)" dataType="number" format="#,##0.#" />
              <Column dataField="freq" caption="Frequency (kHz)" dataType="number" format="#,##0.#" />
              <Column dataField="voltage" caption="Voltage (V)" dataType="number" format="#,##0.#" />
              <Column dataField="duty" caption="Duty (%)" dataType="number" format="#,##0.#" />
              <Column dataField="coolerMode" caption="Cooler Mode" dataType="number" format="#,##0.#" />
              <Column dataField="coolerT" caption="Cooler T (C)" dataType="number" format="#,##0.#" />
              <Column dataField="maxVolume2" caption="Max Volume" />
              <Column dataField="harvestVolume" caption="Harvest Volume (mL)" dataType="number" format="#,##0.#" />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="id" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
