import "./user.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUser } from "../../contexts/user";
// api
import { queryJsonArray, deleteRowById, resetPasswordRowById } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function User() {
  const { user } = useAuth();
  const userContext = useUser();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedId, setSelectedId] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  function onSearchButtonClicked() {
    search();
  }

  function search() {
    setSelectedId("");

    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let query = "SELECT * FROM user";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY id Asc";
    queryJsonArray("/login/queryuser", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < jsonData.length; i++) {
        // 기존 json row에 몇몇 column을 추가
        let oneRowJson: Object | null = jsonData[i];
        let id: string = U.stringValueFromJson(oneRowJson, "id");
        if (id.toLowerCase() === "admin") continue;

        // (tx) [0] Operator, [1] TX Sample R, [2] D, [3] Device R & W, [4] D
        let acc_tx: number = U.intValueFromJson(oneRowJson, "acc_tx");
        // (proj) [0] Project R, [1] W, [2] D, [3] Protocol R, [4] W, [5] D, [6] Cell & Mat R, [7] W, [8] D
        let acc_proj: number = U.intValueFromJson(oneRowJson, "acc_proj");

        let operator: string = "X";
        if (U.bitAt(acc_tx, 0)) operator = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "operator", operator);

        let txResultR: string = "X";
        if (U.bitAt(acc_tx, 1)) txResultR = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "txResultR", txResultR);

        let txResultD: string = "X";
        if (U.bitAt(acc_tx, 2)) txResultD = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "txResultD", txResultD);

        let deviceRW: string = "X";
        if (U.bitAt(acc_tx, 3)) deviceRW = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "deviceRW", deviceRW);

        let deviceD: string = "X";
        if (U.bitAt(acc_tx, 4)) deviceD = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "deviceD", deviceD);

        let libraryR: string = "X";
        if (U.bitAt(acc_proj, 6)) libraryR = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "libraryR", libraryR);

        let libraryW: string = "X";
        if (U.bitAt(acc_proj, 7)) libraryW = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "libraryW", libraryW);

        let libraryD: string = "X";
        if (U.bitAt(acc_proj, 8)) libraryD = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "libraryD", libraryD);

        let protocolR: string = "X";
        if (U.bitAt(acc_proj, 3)) protocolR = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "protocolR", protocolR);

        let protocolW: string = "X";
        if (U.bitAt(acc_proj, 4)) protocolW = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "protocolW", protocolW);

        let protocolD: string = "X";
        if (U.bitAt(acc_proj, 5)) protocolD = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "protocolD", protocolD);

        let projectR: string = "X";
        if (U.bitAt(acc_proj, 0)) projectR = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "projectR", projectR);

        let projectW: string = "X";
        if (U.bitAt(acc_proj, 1)) projectW = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "projectW", projectW);

        let projectD: string = "X";
        if (U.bitAt(acc_proj, 2)) projectD = "O";
        oneRowJson = U.addStringToJson(oneRowJson, "projectD", projectD);

        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }
      setJsonDataSource(newJsonData);
    });
  }

  function onNewButtonClicked() {
    userContext.id = "";
    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }
    userContext.affiliation = userAffiliation;
    navigate("/user/new");
  }

  function copyToUserContext(obj: Object) {
    userContext.id = U.stringValueFromJson(obj, "id");
    userContext.acc_admin = U.stringValueFromJson(obj, "acc_admin");
    userContext.acc_tx = U.stringValueFromJson(obj, "acc_tx");
    userContext.acc_proj = U.stringValueFromJson(obj, "acc_proj");
    userContext.acc_facs = U.stringValueFromJson(obj, "acc_facs");
  }

  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToUserContext(selectedRow);

    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }
    userContext.affiliation = userAffiliation;
    navigate("/user/new");
  }

  function onDeleteButtonClicked() {
    if (selectedId.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedId, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowById("/login/deleteuser", selectedId).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onResetPasswordButtonClicked() {
    if (selectedId.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedId, "Reset password?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        resetPasswordRowById("/login/resetuserpassword", selectedId).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedId(e.selectedRowsData[0].id);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>User</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="success" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Edit" onClick={onEditButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Reset Password as 1234" onClick={onResetPasswordButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="id" caption="Protocol Name" />
              <Column dataField="created_at" caption="Created At" dataType="date" />
              <Column dataField="accessed_at" caption="Accessed At" dataType="date" />
              <Column dataField="operator" caption="Operator" />
              <Column dataField="txResultR" caption="TX Result (R)" />
              <Column dataField="txResultD" caption="TX Result (Del)" />
              <Column dataField="deviceRW" caption="Device (R & W)" />
              <Column dataField="deviceD" caption="Device (Del)" />
              <Column dataField="libraryR" caption="Library (R)" />
              <Column dataField="libraryW" caption="Library (W)" />
              <Column dataField="libraryD" caption="Library (Del)" />
              <Column dataField="protocolR" caption="Protocol (R)" />
              <Column dataField="protocolW" caption="Protocol (W)" />
              <Column dataField="protocolD" caption="Protocol (Del)" />
              <Column dataField="projectR" caption="Project (R)" />
              <Column dataField="projectW" caption="Project (W)" />
              <Column dataField="projectD" caption="Project (Del)" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="id" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
