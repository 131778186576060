import { createContext, useContext } from "react";
export type UserContextType = {
  id: string;
  affiliation: string;
  acc_admin: string;
  acc_tx: string;
  acc_proj: string;
  acc_facs: string;
};
const UserContext = createContext<UserContextType>({} as UserContextType);
const useUser = () => useContext(UserContext);
export { useUser };
