import { createContext, useContext } from "react";
// cartridge는 사용자가 변경할 수 없으므로 로그인할 때 한 번 다운로드 받아서 계속 사용한다.
// src/api/auth.tsx/login 함수에서 호출함
export type CartridgeContextType = {
  catNoList: Array<string>;
  modelCategoryList: Array<string>;
  outflowList: Array<number>;
};
const CartridgeContext = createContext<CartridgeContextType>({} as CartridgeContextType);
const useCartridge = () => useContext(CartridgeContext);
export { useCartridge };
