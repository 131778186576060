import "./projectNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useProject } from "../../contexts/project";
import { useCartridge } from "../../contexts/cartridge";
// api
import { queryJsonArray, deleteRowById, readJson, uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react/check-box";
import Switch from "devextreme-react/switch";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";
import { alert, confirm } from "devextreme/ui/dialog";

export default function ProjectNew() {
  const { user } = useAuth();
  const projectContext = useProject();
  const cartridgeContext = useCartridge();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("New Project");

  const [projectNameList, setProjectNameList] = useState<Array<string>>([]);
  const [projectName, setProjectName] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [strStartDate, setStrStartDate] = useState("");
  const [numberOfUse, setNumberOfUse] = useState("");
  const [projectManager, setProjectManager] = useState("");
  const [cellName, setCellName] = useState("");
  const [cellNameList, setCellNameList] = useState<Array<string>>([]);
  const [isCellNameSelectBoxHidden, setIsCellNameSelectBoxHidden] = useState(false);
  const [isCellNameTextBoxHidden, setIsCellNameTextBoxHidden] = useState(true);
  const [cellType, setCellType] = useState("");
  const [cellTypeList, setCellTypeList] = useState<Array<string>>([]);
  const [cellSize, setCellSize] = useState("");
  const [cellSizeList, setCellSizeList] = useState<Array<string>>([]);
  const [cellBuffer, setCellBuffer] = useState("");
  const [cellBufferList, setCellBufferList] = useState<Array<string>>([]);
  const [cellConc, setCellConc] = useState("");
  const [matName, setMatName] = useState("");
  const [matNameList, setMatNameList] = useState<Array<string>>([]);
  const [isMatNameSelectBoxHidden, setIsMatNameSelectBoxHidden] = useState(false);
  const [isMatNameTextBoxHidden, setIsMatNameTextBoxHidden] = useState(true);
  const [matType, setMatType] = useState("");
  const [matTypeList, setMatTypeList] = useState<Array<string>>([]);
  const [matMolWeight, setMatMolWeight] = useState("");
  const [matMolWeightList, setMatMolWeightList] = useState<Array<string>>([]);
  const [matBuffer, setMatBuffer] = useState("");
  const [matBufferList, setMatBufferList] = useState<Array<string>>([]);
  const [matConc, setMatConc] = useState("");

  const [isDeviceModelSelectBoxHidden, setIsDeviceModelSelectBoxHidden] = useState(false);
  const [isDeviceModelTextBoxHidden, setIsDeviceModelTextBoxHidden] = useState(true);
  const [deviceModel, setDeviceModel] = useState("");
  const [deviceModelList, setDeviceModelList] = useState<Array<string>>([]);
  const [deviceModelFullList, setDeviceModelFullList] = useState<Array<string>>([]);

  const [isAuxModuleSelectBoxHidden, setIsAuxModuleSelectBoxHidden] = useState(false);
  const [isAuxModuleTextBoxHidden, setIsAuxModuleTextBoxHidden] = useState(true);
  const [auxModule, setAuxModule] = useState("");
  const [auxModuleList, setAuxModuleList] = useState<Array<string>>([]);
  const [auxModuleFullList, setAuxModuleFullList] = useState<Array<string>>([]);

  const [isProtocolNameSelectBoxHidden, setIsProtocolNameSelectBoxHidden] = useState(false);
  const [isProtocolNameTextBoxHidden, setIsProtocolNameTextBoxHidden] = useState(true);
  const [protocolName, setProtocolName] = useState("");
  const [protocolNameList, setProtocolNameList] = useState<Array<string>>([]); // 선택된 modelCategory에 해당하는 protocol list
  const [protocolNameFullList, setProtocolNameFullList] = useState<Array<string>>([]);
  const [cartridge, setCartridge] = useState("");
  const [cartridgeFullList, setCartridgeFullList] = useState<Array<string>>([]);
  const [outflow, setOutflow] = useState("");
  const [outflowFullList, setOutflowFullList] = useState<Array<string>>([]);
  const [mixingRatio, setMixingRatio] = useState("");
  const [mixingRatioFullList, setMixingRatioFullList] = useState<Array<string>>([]);
  const [freq, setFreq] = useState("");
  const [freqFullList, setFreqFullList] = useState<Array<string>>([]);
  const [voltage, setVoltage] = useState("");
  const [voltageFullList, setVoltageFullList] = useState<Array<string>>([]);
  const [duty, setDuty] = useState("");
  const [dutyFullList, setDutyFullList] = useState<Array<string>>([]);
  const [coolerMode, setCoolerMode] = useState("");
  const [coolerModeFullList, setCoolerModeFullList] = useState<Array<string>>([]);
  const [coolerT, setCoolerT] = useState("");
  const [coolerTFullList, setCoolerTFullList] = useState<Array<string>>([]);
  const [maxVolume, setMaxVolume] = useState("");
  const [maxVolumeFullList, setMaxVolumeFullList] = useState<Array<string>>([]);
  const [harvestVolume, setHarvestVolume] = useState("");
  const [harvestVolumeFullList, setHarvestVolumeFullList] = useState<Array<string>>([]);

  const [note, setNote] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let userId: string = "";
    if (user) {
      userId = user.id;
    }
    setStatus("Active");

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      initializeList();
    }

    // SelectBox의 값을 설정할 때에는 dataSource의 list 안에 있는 값만 적용된다. 다른 값을 설정하면 화면에는 선택 안된 걸로 표시된다.
    if (0 < projectContext.id.length) {
      // edit
      setPageTitle("Edit Project");
      setProjectName(projectContext.id);
      setStatus(projectContext.status);
      setProjectManager(projectContext.manager);
      setStartDate(projectContext.startDate);
      setStrStartDate(U.eightDigitDotStringDateFromDate(projectContext.startDate));
      setNumberOfUse(projectContext.numberOfUse);
      setCellName(projectContext.cellName);
      setIsCellNameSelectBoxHidden(true);
      setIsCellNameTextBoxHidden(false);
      setCellType(projectContext.cellType);
      setCellSize(projectContext.cellSize);
      setCellBuffer(projectContext.cellBuffer);
      setCellConc(projectContext.cellConc);
      setMatName(projectContext.matName);
      setIsMatNameSelectBoxHidden(true);
      setIsMatNameTextBoxHidden(false);
      setMatType(projectContext.matType);
      setMatMolWeight(projectContext.matMolWeight);
      setMatBuffer(projectContext.matBuffer);
      setMatConc(projectContext.matConc);

      setDeviceModel(projectContext.deviceModel);
      setAuxModule(projectContext.auxModule);
      setProtocolName(projectContext.protocol);
      setIsProtocolNameSelectBoxHidden(true);
      setIsProtocolNameTextBoxHidden(false);
      setCartridge(projectContext.cartridge);
      setOutflow(projectContext.outflow);
      setMixingRatio(projectContext.mixingRatio);
      setFreq(projectContext.freq);
      setVoltage(projectContext.voltage);
      setDuty(projectContext.duty);
      let coolerModeN: number = U.intFromString(projectContext.coolerMode, 0);
      if (coolerModeN == 0) setCoolerMode("Off");
      else setCoolerMode("On");
      setCoolerT(projectContext.coolerT);

      let maxVolumeN: number = U.intFromString(projectContext.maxVolume, 0);
      if (maxVolumeN == 0) {
        setMaxVolume("Off");
      } else {
        setMaxVolume("On");
      }
      setHarvestVolume(projectContext.harvestVolume);
      setNote(projectContext.note);
      setIsCellNameSelectBoxHidden(true);
      setIsCellNameTextBoxHidden(false);
      setIsMatNameSelectBoxHidden(true);
      setIsMatNameTextBoxHidden(false);
      setIsDeviceModelSelectBoxHidden(true);
      setIsDeviceModelTextBoxHidden(false);
      setIsAuxModuleSelectBoxHidden(true);
      setIsAuxModuleTextBoxHidden(false);
      setIsProtocolNameSelectBoxHidden(true);
      setIsProtocolNameTextBoxHidden(false);
    } else {
      setPageTitle("New Project");
      setProjectManager(userId);
      setStartDate(new Date());
      setStrStartDate(U.eightDigitDotStringDateFromDate(new Date()));
      setNumberOfUse("0");
      setIsCellNameSelectBoxHidden(false);
      setIsCellNameTextBoxHidden(true);
      setIsMatNameSelectBoxHidden(false);
      setIsMatNameTextBoxHidden(true);
      setIsDeviceModelSelectBoxHidden(false);
      setIsDeviceModelTextBoxHidden(true);
      setIsAuxModuleSelectBoxHidden(false);
      setIsAuxModuleTextBoxHidden(true);
      setIsProtocolNameSelectBoxHidden(false);
      setIsProtocolNameTextBoxHidden(true);
      setNote("");
    }
  }, []);

  function initializeList() {
    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let query = "SELECT model FROM device";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY model Asc";
    queryJsonArray("/project/queryproject", query).then((deviceJson: Array<Object>) => {
      let dmList = new Array<string>();
      let hasBadger700t: boolean = false;
      let hasBadger700d: boolean = false;
      let hasBadger2: boolean = false;
      let hasMenorah: boolean = false;
      for (let i = 0; i < deviceJson.length; i++) {
        let modelName: string = U.stringValueFromJson(deviceJson[i], "model");
        if (modelName === "Badger 700T") hasBadger700t = true;
        if (modelName === "Badger 700D") hasBadger700d = true;
        if (modelName === "Badger-II") hasBadger2 = true;
        if (modelName === "Menorah") hasMenorah = true;
      }
      if (hasBadger700t) dmList.push("Badger 700T");
      if (hasBadger700d) dmList.push("Badger 700D");
      if (hasBadger2) dmList.push("Badger-II");
      if (hasMenorah) dmList.push("Menorah");
      setDeviceModelList(dmList);
    });

    // cell, mat은 같이 연산할 내용은 없으므로 각자 query 및 then 처리를 해준다.
    query = "SELECT name, type, size, buffer FROM cell";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY name Asc";
    queryJsonArray("/project/queryproject", query).then((cellJson: Array<Object>) => {
      let nameList = [];
      let typeList = [];
      let sizeList = [];
      let bufferList = [];
      for (let i = 0; i < cellJson.length; i++) {
        nameList.push(U.stringValueFromJson(cellJson[i], "name"));
        typeList.push(U.stringValueFromJson(cellJson[i], "type"));
        sizeList.push(U.floatValueFromJson(cellJson[i], "size").toString());
        bufferList.push(U.stringValueFromJson(cellJson[i], "buffer"));
      }
      setCellNameList(nameList);
      setCellTypeList(typeList);
      setCellSizeList(sizeList);
      setCellBufferList(bufferList);
    });

    query = "SELECT name, type, molWeight, buffer FROM mat";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY name Asc";
    queryJsonArray("/project/queryproject", query).then((matJson: Array<Object>) => {
      let nameList = [];
      let typeList = [];
      let molWeightList = [];
      let bufferList = [];
      for (let i = 0; i < matJson.length; i++) {
        nameList.push(U.stringValueFromJson(matJson[i], "name"));
        typeList.push(U.stringValueFromJson(matJson[i], "type"));
        molWeightList.push(U.floatValueFromJson(matJson[i], "molWeight").toString());
        bufferList.push(U.stringValueFromJson(matJson[i], "buffer"));
      }
      setMatNameList(nameList);
      setMatTypeList(typeList);
      setMatMolWeightList(molWeightList);
      setMatBufferList(bufferList);
    });

    query = "SELECT * FROM protocol";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY id Asc";
    queryJsonArray("/project/queryproject", query).then((protocolJson: Array<Object>) => {
      let deviceModelList = [];
      let auxModuleList = [];
      let idList = [];
      let cartridgeList = [];
      let outflowList = [];
      let mixingRatioList = [];
      let freqList = [];
      let voltageList = [];
      let dutyList = [];
      let coolerModeList = [];
      let coolerTList = [];
      let maxVolumeList = [];
      let harvestVolumeList = [];
      for (let i = 0; i < protocolJson.length; i++) {
        idList.push(U.stringValueFromJson(protocolJson[i], "id"));
        deviceModelList.push(U.stringValueFromJson(protocolJson[i], "deviceModel"));
        auxModuleList.push(U.stringValueFromJson(protocolJson[i], "auxModule"));
        cartridgeList.push(U.stringValueFromJson(protocolJson[i], "cartridge"));
        outflowList.push(U.floatValueFromJson(protocolJson[i], "outflow").toString());
        mixingRatioList.push(U.floatValueFromJson(protocolJson[i], "mixingRatio").toString());
        freqList.push(U.floatValueFromJson(protocolJson[i], "freq").toString());
        voltageList.push(U.floatValueFromJson(protocolJson[i], "voltage").toString());
        dutyList.push(U.floatValueFromJson(protocolJson[i], "duty").toString());
        coolerModeList.push(U.intValueFromJson(protocolJson[i], "coolerMode").toString());
        coolerTList.push(U.floatValueFromJson(protocolJson[i], "coolerT").toString());
        maxVolumeList.push(U.intValueFromJson(protocolJson[i], "maxVolume").toString());
        harvestVolumeList.push(U.floatValueFromJson(protocolJson[i], "harvestVolume").toString());
      }
      setProtocolNameFullList(idList);
      setDeviceModelFullList(deviceModelList);
      setAuxModuleFullList(auxModuleList);
      setCartridgeFullList(cartridgeList);
      setOutflowFullList(outflowList);
      setMixingRatioFullList(mixingRatioList);
      setFreqFullList(freqList);
      setVoltageFullList(voltageList);
      setDutyFullList(dutyList);
      setCoolerModeFullList(coolerModeList);
      setCoolerTFullList(coolerTList);
      setMaxVolumeFullList(maxVolumeList);
      setHarvestVolumeFullList(harvestVolumeList);
    });

    query = "SELECT id FROM project";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY id Asc";
    queryJsonArray("/project/queryproject", query).then((jsonData: Array<Object>) => {
      let nameList = [];
      for (let i = 0; i < jsonData.length; i++) {
        nameList.push(U.stringValueFromJson(jsonData[i], "id"));
      }
      setProjectNameList(nameList); // next name을 YYMMNNN 형태로 자동설정하기 위해 기존의 project name list를 받아옴
    });
  }

  function onStatusSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setStatus(str);
  }

  function updateProjectName(currentCellName: string, currentMatName: string, currentVoltage: string, currentDuty: string) {
    // CHO-K1-pGFP(PBS)-2409001
    // -> CHO-K1_pGFP(PBS)_20_50_24001  (20: 2000V, 50: 50% duty)
    let date = new Date();
    let year: number = date.getFullYear(); // 2024
    let month: number = date.getMonth() + 1; // getMonth(): zero-based value (즉 1월이 0)
    let strYear: string = year.toString();
    let yy: string = strYear.substring(2, 4);

    let v: number = U.intFromString(currentVoltage, 0);
    let v2: number = v / 100;
    let vPart: string = v2.toString();
    if (vPart.length == 1) vPart = "0" + vPart;

    let dPart: string = currentDuty;
    if (dPart.length == 0) dPart = "0" + dPart;
    if (dPart.length == 1) dPart = "0" + dPart;

    let prefix: string = `${currentCellName}_${currentMatName}_${vPart}_${dPart}_${yy}`; // 마지막 NNN 3자리 제외한 앞부분

    let maxIndex: number = 0;
    for (let i = 0; i < projectNameList.length; i++) {
      let listName: string = projectNameList[i];
      if (listName.length < 7) continue;
      let listYy: string = listName.substring(listName.length - 5, listName.length - 3);
      let listIndex: string = listName.substring(listName.length - 3, listName.length);
      if (yy === listYy) {
        let index: number = U.intFromString(listIndex, 0);
        if (maxIndex < index) maxIndex = index;
      }
    }
    let strNewIndex: string = "";
    let newIndex: number = maxIndex + 1;
    if (newIndex < 10) strNewIndex = "00" + newIndex.toString();
    else {
      if (newIndex < 100) strNewIndex = "0" + newIndex.toString();
      else strNewIndex = newIndex.toString();
    }
    if (strNewIndex.length > 3) strNewIndex = "999";
    let newName: string = `${prefix}${strNewIndex}`;
    setProjectName(newName);
  }

  function onCellNameSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setCellName(str);
    let index: number = U.indexOfStringArray(str, cellNameList);
    if (index < 0) return;
    setCellType(cellTypeList[index]);
    setCellSize(cellSizeList[index]);
    setCellBuffer(cellBufferList[index]);

    updateProjectName(str, matName, voltage, duty);
  }
  function onCellConcTextBoxValueChanged(e: string) {
    setCellConc(e);
  }
  function onMatNameSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setMatName(str);
    let index: number = U.indexOfStringArray(str, matNameList);
    if (index < 0) return;
    setMatType(matTypeList[index]);
    setMatMolWeight(matMolWeightList[index]);
    setMatBuffer(matBufferList[index]);

    updateProjectName(cellName, str, voltage, duty);
  }
  function onMatConcTextBoxValueChanged(e: string) {
    setMatConc(e);
  }

  function onDeviceModelSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setDeviceModel(str);
    if (str === "Badger 700T" || str === "Badger 700D") {
      let amList = new Array<string>();
      amList.push("");
      amList.push("Turtle-I");
      amList.push("Turtle-II");
      setAuxModuleList(amList);
    } else {
      let amList = new Array<string>();
      setAuxModuleList(amList);
    }
    setAuxModule("");
    updateProtocolNameList(str, "");
    setProtocolName("");
    setCartridge("");
    setOutflow("");
    setMixingRatio("");
    setFreq("");
    setVoltage("");
    setDuty("");
    setMaxVolume("");
    setHarvestVolume("");
    updateProjectName(cellName, matName, "", "");
  }

  function onAuxModuleSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setAuxModule(str);
    updateProtocolNameList(deviceModel, str);
    setProtocolName("");
    setCartridge("");
    setOutflow("");
    setMixingRatio("");
    setFreq("");
    setVoltage("");
    setDuty("");
    setMaxVolume("");
    setHarvestVolume("");

    updateProjectName(cellName, matName, "", "");
  }

  function updateProtocolNameList(currentDeviceModel: string, currentAuxModule: string) {
    // modelCategory에 해당하는 protocol list를 추려서 combobox에 보여줌
    let ptList = new Array<string>();
    for (let i = 0; i < protocolNameFullList.length; i++) {
      if (currentDeviceModel === deviceModelFullList[i] && currentAuxModule === auxModuleFullList[i]) {
        ptList.push(protocolNameFullList[i]);
      }
    }
    setProtocolNameList(ptList);
  }

  function onProtocolNameSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setProtocolName(str);
    let index: number = U.indexOfStringArray(str, protocolNameFullList);
    if (index < 0) return;
    setCartridge(cartridgeFullList[index]);
    setOutflow(outflowFullList[index]);
    setMixingRatio(mixingRatioFullList[index]);
    setFreq(freqFullList[index]);
    setVoltage(voltageFullList[index]);
    setDuty(dutyFullList[index]);
    let strCooler: string = "On";
    if (coolerModeFullList[index] === "0") strCooler = "Off";
    setCoolerMode(strCooler);
    setCoolerT(coolerTFullList[index]);
    let strMaxVolume: string = "On";
    if (maxVolumeFullList[index] === "0") strMaxVolume = "Off";
    setMaxVolume(strMaxVolume);
    setHarvestVolume(harvestVolumeFullList[index]);

    updateProjectName(cellName, matName, voltageFullList[index], dutyFullList[index]);
  }

  function onNoteTextBoxValueChanged(e: string) {
    setNote(e);
  }

  async function onUploadButtonClicked() {
    if (cellName.length === 0) {
      alert("Select cell name!", "Error");
      return;
    }
    if (matName.length === 0) {
      alert("Select mat name!", "Error");
      return;
    }
    if (protocolName.length === 0) {
      alert("Select protocol!", "Error");
      return;
    }
    if (projectName.length === 0) {
      alert("Enter project name!", "Error");
      return;
    }
    if (cartridge.length === 0) {
      alert("Select cartridge!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let cellConcF: number = U.floatFromString(cellConc, 0);
    if (cellConcF < 0) {
      alert("Enter proper cell conc. value!", "Error");
      return;
    }

    let matConcF: number = U.floatFromString(matConc, 0);
    if (matConcF < 0) {
      alert("Enter proper mat conc. value!", "Error");
      return;
    }

    let cellSizeF: number = U.floatFromString(cellSize, 0);
    let matMolWeightF: number = U.floatFromString(matMolWeight, 0);
    let outflowF: number = U.floatFromString(outflow, 0);
    let mixingRatioF: number = U.floatFromString(mixingRatio, 0);
    let freqF: number = U.floatFromString(freq, 0);
    if (freqF !== 1 && freqF !== 2 && freqF !== 3 && freqF !== 4 && freqF !== 5 && freqF !== 6 && freqF !== 7 && freqF !== 8 && freqF !== 9 && freqF !== 10) {
      alert("Enter integer value between 1 ~ 10 for frequency!", "Error");
      return;
    }
    let voltageF: number = U.floatFromString(voltage, 0);
    let dutyF: number = U.floatFromString(duty, 0);
    let coolerModeN: number = 1;
    if (coolerMode === "Off") coolerModeN = 0;
    let coolerTF: number = U.floatFromString(coolerT, 0);
    let harvestVolumeF: number = U.floatFromString(harvestVolume, 0);
    let maxVolumeN: number = 1;
    if (maxVolume === "Off") maxVolumeN = 0;
    else harvestVolumeF = 0;

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let projectName2: string = U.replaceBrace(projectName);
    let note2: string = U.replaceBrace(note);

    let obj: Object = {
      id: projectName2,
      status: status,
      manager: projectManager,
      startDate: startDate,
      numberOfUse: numberOfUse,
      cellName: cellName,
      cellType: cellType,
      cellSize: cellSizeF,
      cellBuffer: cellBuffer,
      cellConc: cellConcF,
      matName: matName,
      matType: matType,
      matMolWeight: matMolWeightF,
      matBuffer: matBuffer,
      matConc: matConcF,
      deviceModel: deviceModel,
      auxModule: auxModule,
      protocol: protocolName,
      cartridge: cartridge,
      outflow: outflowF,
      mixingRatio: mixingRatioF,
      freq: freqF,
      voltage: voltageF,
      duty: dutyF,
      coolerMode: coolerModeN,
      coolerT: coolerTF,
      maxVolume: maxVolumeN,
      harvestVolume: harvestVolumeF,
      note: note2,
    };

    await uploadJson("/project/uploadproject", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        // 22: Same id exists when upload new item
        let res = confirm("Same id exists! Do you want to overwrite?", "Warning");
        res.then((dialogResult) => {
          if (dialogResult) {
            // Yes
            uploadJsonAndAlert("/project/overwriteproject", obj);
            navigate(-1);
          } else return;
        });
      } else {
        if (response_status === 23) {
          // 23: Row with same major properties exists when upload new item
          let res = confirm("Project with same cell, mat and protocol exists! Continue?", "Warning");
          res.then((dialogResult) => {
            if (dialogResult) {
              // Yes
              uploadJsonAndAlert("/project/overwriteproject", obj);
              navigate(-1);
            } else return;
          });
        } else {
          alert("Failed to upload!", "Error");
          return;
        }
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="Project Name" value={projectName} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <SelectBox label="Status" dataSource={["Active", "Inactive"]} value={status} width={300} onSelectionChanged={onStatusSelectionChanged} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Project Manager" value={projectManager} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Start Date" value={strStartDate} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Number of Use" value={numberOfUse} readOnly={true} width={300} />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-title"}>Cell</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item2"} hidden={isCellNameSelectBoxHidden}>
              <SelectBox label="Cell Name" dataSource={cellNameList} value={cellName} width={300} onSelectionChanged={onCellNameSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isCellNameTextBoxHidden}>
              <TextBox label="Cell Name" value={cellName} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cell Type" value={cellType} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cell Size (um)" value={cellSize} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cell Buffer" value={cellBuffer} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Cell Conc. (10^6 cells/mL)"
                value={cellConc}
                valueChangeEvent="keyup"
                onValueChange={onCellConcTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-title"}>Material</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item2"} hidden={isMatNameSelectBoxHidden}>
              <SelectBox label="Mat Name" dataSource={matNameList} value={matName} width={300} onSelectionChanged={onMatNameSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isMatNameTextBoxHidden}>
              <TextBox label="Mat Name" value={matName} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mat Type" value={matType} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mat Mol. Weight (kDa)" value={matMolWeight} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mat Buffer" value={matBuffer} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Mat Conc. (ug/mL)"
                value={matConc}
                valueChangeEvent="keyup"
                onValueChange={onMatConcTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-title"}>Protocol</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item2"} hidden={isDeviceModelSelectBoxHidden}>
              <SelectBox label="Device Model" dataSource={deviceModelList} value={deviceModel} width={300} onSelectionChanged={onDeviceModelSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isDeviceModelTextBoxHidden}>
              <TextBox label="Device Model" value={deviceModel} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"} hidden={isAuxModuleSelectBoxHidden}>
              <SelectBox label="Aux Module" dataSource={auxModuleList} value={auxModule} width={300} onSelectionChanged={onAuxModuleSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isAuxModuleTextBoxHidden}>
              <TextBox label="Aux Module" value={auxModule} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"} hidden={isProtocolNameSelectBoxHidden}>
              <SelectBox label="Protocol" dataSource={protocolNameList} value={protocolName} width={300} onSelectionChanged={onProtocolNameSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isProtocolNameTextBoxHidden}>
              <TextBox label="Protocol" value={protocolName} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cartridge" value={cartridge} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Outflow (mL/h)" value={outflow} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mixing Ratio (Mat/Cell)" value={mixingRatio} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Frequency (kHz)" value={freq} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Voltage (V)" value={voltage} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Duty (%)" value={duty} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cooler Mode" value={coolerMode} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cooler T (C)" value={coolerT} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Max Volume" value={maxVolume} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Harvest Volume (mL)" value={harvestVolume} readOnly={true} width={300} />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-title"}>Note</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="Note" value={note} valueChangeEvent="keyup" onValueChange={onNoteTextBoxValueChanged} width={700} showClearButton={true} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="success" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
