import "./device.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { useDevice } from "../../contexts/device";
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";
import { test, queryJsonArray, deleteRowById } from "../../api/auth";
import * as U from "../../utils";
import { useNavigate } from "react-router-dom";

export default function Device() {
  const { user } = useAuth();
  const deviceContext = useDevice();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedDeviceSerial, setSelectedDeviceSerial] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(true);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_tx = 0;
    if (user) {
      setUserId(user.id);
      acc_tx = user.acc_tx;
    }
    // (tx) [3] Device R & W, [4] D
    if (U.bitAt(acc_tx, 4)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  function onSearchButtonClicked() {
    search();
  }

  function search() {
    setSelectedDeviceSerial("");

    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let query = "SELECT * FROM device";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY deviceSerial Asc";
    queryJsonArray("/tx/querydevice", query).then((jsonData: Array<Object>) => {
      // 마지막 메시지 수신 후 6분 이상 지났으면 status2 = Offline으로 표시해준다.
      let newJsonArray = new Array<Object>();
      let dateNow = new Date(Date.now());
      for (let i = 0; i < jsonData.length; i++) {
        let updated_at = U.dateValueFromJson(jsonData[i], "updated_at");
        let elapsedTime_ms = dateNow.getTime() - updated_at.getTime();
        let status2: string = U.stringValueFromJson(jsonData[i], "status");
        if (360000 < elapsedTime_ms) status2 = "Offline";
        let obj: Object | null = U.addStringToJson(jsonData[i], "status2", status2);
        if (obj !== null) newJsonArray.push(obj);
      }

      setJsonDataSource(newJsonArray);
    });
  }

  function copyToDeviceContext(obj: Object) {
    deviceContext.deviceSerial = U.stringValueFromJson(obj, "deviceSerial");
    deviceContext.location = U.stringValueFromJson(obj, "location");
    deviceContext.timeZone = U.stringValueFromJson(obj, "timeZone");
  }

  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    if (selectedDeviceSerial.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    copyToDeviceContext(selectedRow);
    navigate("/device/new");
  }

  function onDeleteButtonClicked() {
    if (selectedDeviceSerial.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedDeviceSerial, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowById("/tx/deletedevice", selectedDeviceSerial).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedDeviceSerial(e.selectedRowsData[0].deviceSerial);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Device Monitoring</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="success" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="Edit" onClick={onEditButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="deviceSerial" caption="Device Serial" />
              <Column dataField="status2" caption="Status" />
              <Column dataField="userId" caption="User ID" />
              <Column dataField="updated_at" caption="Last Update" dataType="datetime" />
              <Column dataField="project" caption="Project" />
              <Column dataField="model" caption="Model" />
              <Column dataField="auxModule" caption="Aux Module" />
              <Column dataField="ampType" caption="Amp Type" />
              <Column dataField="swVersion" caption="SW Version" />
              <Column dataField="timeZone" caption="Time Zone" />
              <Column dataField="location" caption="Location" />
              <Column dataField="temperature" caption="Temperature (°C)" />
              <Column dataField="humidity" caption="Humidity (%RH)" />
              <Column dataField="fanTime" caption="Fan Time (hr)" format={"0.#"} />
              <Column dataField="fcc2310" caption="FCC-2310 Usage" />
              <Column dataField="fcc332t" caption="FCC-332T Usage" />
              <Column dataField="fcc335t" caption="FCC-335T Usage" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="deviceSerial" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
